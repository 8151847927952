// All components mapping with path for internal routes

import { lazy } from 'react'
import InboxArrowDownIcon from "@heroicons/react/24/outline/InboxArrowDownIcon";
// import SaleMemberDetails from "../features/salemember-details";


const Dashboard = lazy(() => import('../pages/protected/Dashboard'))
const Welcome = lazy(() => import('../pages/protected/Welcome'))
const Page404 = lazy(() => import('../pages/protected/404'))
const Blank = lazy(() => import('../pages/protected/Blank'))
const Charts = lazy(() => import('../pages/protected/Charts'))
const Leads = lazy(() => import('../pages/protected/Leads'))
const Zone = lazy(() => import('../pages/protected/Zone'))
const Integration = lazy(() => import('../pages/protected/Integration'))
const Calendar = lazy(() => import('../pages/protected/Calendar'))
const Team = lazy(() => import('../pages/protected/Team'))
const Transactions = lazy(() => import('../pages/protected/Transactions'))
const Bills = lazy(() => import('../pages/protected/Bills'))
const ProfileSettings = lazy(() => import('../pages/protected/ProfileSettings'))
const GettingStarted = lazy(() => import('../pages/GettingStarted'))
const DocFeatures = lazy(() => import('../pages/DocFeatures'))
const DocComponents = lazy(() => import('../pages/DocComponents'))
const Meeting = lazy(() => import('../features/meeting'))
const CargoStatus = lazy(() => import('../features/cargoStatus'))
const CargoPrice = lazy(() => import('../features/cargoPrice'))
const FeedBack = lazy(() => import('../features/feedBack'))
const Notification = lazy(() => import('../features/notification'))

const UserRouteTracker = lazy(() => import('../features/routeTracking/routeTracker'))
const PermissionType = lazy(() => import('../pages/protected/PermissionType'))
const Permissions = lazy(() => import('../pages/protected/Permissions'))
const Notifications = lazy(() => import('../pages/protected/Notifications'))
const SaleMemberDetails = lazy(() => import('../features/salemember-details'))
const Sponsorship = lazy(() => import("../features/sponsorship"))
const SponsorshipDetails = lazy(() => import("../features/sponsorship/SponsorshipDetails"))
const routes = [
  {
    path: 'dashboard', // the url
    component: Dashboard, // view rendered
  },
  {
    path: 'welcome', // the url
    component: Welcome, // view rendered
  },
  {
    path: 'leads',
    component: Leads,
  },
  {
    path: 'settings-team',
    component: Team,
  },
  {
    path: 'calendar',
    component: Calendar,
  },
  {
    path: 'transactions',
    component: Transactions,
  },
  {
    path: 'settings-profile',
    component: ProfileSettings,
  },
  {
    path: 'settings-billing',
    component: Bills,
  },
  {
    path: 'getting-started',
    component: GettingStarted,
  },
  {
    path: 'features',
    component: DocFeatures,
  },
  {
    path: 'zone',
    component: Zone,
  },

  {
    path: 'components',
    component: DocComponents,
  },
  {
    path: 'integration',
    component: Integration,
  },
  {
    path: 'charts',
    component: Charts,
  },
  {
    path: 'meeting',
    component: Meeting,
  },{
    path: 'route-tracker',
    component: UserRouteTracker,
  }, {
    path: 'cargo-price',
    component: CargoPrice,
  }, {
    path: 'cargo-status',
    component: CargoStatus,
  },
  {
    path: 'feedback',
    component: FeedBack,
  },

  {
    path: '404',
    component: Page404,
  },
  {
    path: 'blank',
    component: Blank,
  },
  {
    path: 'permission-type',
    component: PermissionType,
  },
  {
    path: 'permissions',
    component: Permissions,
  },
  {
    path: 'notifications',
    component: Notifications,
  },
  {
    path: 'sponsorship',
    component: Sponsorship,
  },
  {
    path: 'sponsorship-details/:id',
    component: SponsorshipDetails,
  },
  {
    path: 'leads-details/:id',
    component:SaleMemberDetails,
  },
]

export default routes
